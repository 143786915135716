import React from "react";
import styles from "./Footer.module.css";

const Footer: React.FC = () => {
  return (
    <footer role="contentinfo">
      <div className={styles.row} data-nosnippet="true">
        <div className={styles.col}>
          <div className={styles["vt-footer-row"]}>
            <div className={styles["vt-footer-info"]}>
              <div className={styles["vt-embed-map"]}>
                <img
                  className={styles["vt-map-image"]}
                  src="https://www.assets.cms.vt.edu/images/vt-campuses.svg"
                  data-longdesc="https://www.vt.edu/maps.html#locations"
                  alt="Map of Virginia with pins showing locations of Virginia Tech campuses. See All Locations link which follows provides details"
                />
              </div>
              <div className={styles["vt-footer-infoWrapper"]}>
                <div className={styles["vt-footer-logoWrapper"]}>
                  <img
                    className={styles["vt-footer-logo"]}
                    src="https://www.assets.cms.vt.edu/images/logo-white-black.svg"
                    alt="Virginia Tech logo"
                  />
                </div>
                <div className={styles["vt-footer-info-linkWrapper"]}>
                  <p className={styles["vt-footer-directions"]}>
                    <a
                      className={styles["footer-info-link"]}
                      href="https://www.vt.edu/maps/directions.html"
                    >
                      Get Directions&nbsp;&nbsp;
                      <svg
                        className={`${styles["svg-inline--fa"]} ${styles["fa-w-14"]}`}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="arrow-right"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                        ></path>
                      </svg>
                    </a>
                  </p>
                  <p className={styles["vt-footer-locations"]}>
                    <a
                      className={styles["footer-info-link"]}
                      href="https://www.vt.edu/maps.html#locations"
                    >
                      See All Locations&nbsp;&nbsp;
                      <svg
                        className={`${styles["svg-inline--fa"]} ${styles["fa-w-14"]}`}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="arrow-right"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                        ></path>
                      </svg>
                    </a>
                  </p>
                  <p className={styles["vt-footer-contact"]}>
                    <a
                      className={styles["footer-info-link"]}
                      href="https://www.vt.edu/contacts.html"
                    >
                      Contact Virginia Tech&nbsp;&nbsp;
                      <svg
                        className={`${styles["svg-inline--fa"]} ${styles["fa-w-14"]}`}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="arrow-right"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
                        ></path>
                      </svg>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles["vt-footer-rowSpacer"]}></div>
            <div className={styles["vt-footer-links"]}>
              <ul className={styles["vt-footer-linksCol"]}>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.vt.edu/status.html"
                  >
                    University Status
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.vt.edu/accessibility.html"
                  >
                    Accessibility
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.wvtf.org"
                  >
                    WVTF
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.vt.edu/principles-of-community.html"
                  >
                    Principles of Community
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://aie.vt.edu/strategic-analysis/public-disclosure-requirements/student-consumer-information.html"
                  >
                    Consumer Information
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.bkstr.com/virginiatechstore/home"
                  >
                    University Bookstore
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.vt.edu/privacy.html"
                  >
                    Privacy Statement
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.vt.edu/admissions/cost-and-aid.html"
                  >
                    Cost &amp; Aid
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://jobs.vt.edu"
                  >
                    Jobs at Virginia Tech
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.vt.edu/acceptable-use.html"
                  >
                    Acceptable Use
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://safe.vt.edu"
                  >
                    SAFE at VT
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.vt.edu/strategic-plan.html"
                  >
                    Strategic Plan
                  </a>
                </li>
                <li>
                  <a
                    className={`${styles["vt-footer-link"]} ${styles["vt-remember"]}`}
                    href="https://www.weremember.vt.edu/"
                    aria-label="We Remember"
                  >
                    We Remember
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://policies.vt.edu"
                  >
                    Policies
                  </a>
                </li>
                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://lib.vt.edu"
                  >
                    University Libraries
                  </a>
                </li>

                <li>
                  <a
                    className={styles["vt-footer-link"]}
                    href="https://www.vt.edu/equal-opportunity.html"
                  >
                    Equal Opportunity
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={`${styles["vt-footer-row"]} ${styles["vt-footer-bottom"]}`}
          >
            <div id={styles["vt_footer_copyright"]}>
              <p className={styles["vt-copyright"]}>
                ©&nbsp;2025 Virginia Polytechnic Institute and State University.
                All rights reserved.
              </p>
            </div>

            <ul className={styles["follow-virginia-tech-icons"]}>
              <li>
                <a
                  href="https://facebook.com/virginiatech"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook"
                >
                  <svg
                    className={`${styles["svg-inline--fa"]} ${styles["fa-w-14"]}`}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook-square"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                    ></path>
                  </svg>
                  <span className={styles["sr-only"]}>Facebook</span>
                </a>
              </li>

              <li>
                <a
                  href="https://x.com/virginia_tech"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="X"
                >
                  <img
                    src="https://www.assets.cms.vt.edu/images/social-media/x-logo-white.svg"
                    aria-hidden="true"
                    alt="X"
                  />
                  <span className={styles["sr-only"]}>X</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.threads.net/@virginia.tech"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Threads"
                >
                  <img
                    src="https://www.assets.cms.vt.edu/images/threads_logo_white.svg"
                    aria-hidden="true"
                    alt="Threads"
                  />
                  <span className={styles["sr-only"]}>Threads</span>
                </a>
              </li>

              <li>
                <a
                  href="https://instagram.com/virginia.tech"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                >
                  <svg
                    className={`${styles["svg-inline--fa"]} ${styles["fa-w-14"]}`}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    ></path>
                  </svg>
                  <span className={styles["sr-only"]}>Instagram</span>
                </a>
              </li>

              <li>
                <a
                  href="http://www.linkedin.com/edu/school?id=19611&amp;trk"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Linked In"
                >
                  <svg
                    className={`${styles["svg-inline--fa"]} ${styles["fa-w-14"]}`}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                    ></path>
                  </svg>
                  <span className={styles["sr-only"]}>Linked In</span>
                </a>
              </li>

              <li>
                <a
                  href="https://www.youtube.com/user/VirginiaTech"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Youtube"
                >
                  <svg
                    className={`${styles["svg-inline--fa"]} ${styles["fa-w-18"]}`}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="youtube"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                    ></path>
                  </svg>
                  <span className={styles["sr-only"]}>YouTube</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
